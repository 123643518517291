<template lang="pug">
  include ../../../assets/pug/index
  .py-5.px-5(style="background-color: #fff")
    div
      +inputValidation('version', 'enterVersion', '["required", "length", "VERSION_REGEX"]').my-3(
        :placeholder="$t('versionPlaceholder')"
        )
      vue-editor(v-model="content")
      .d-flex.justify-end
        +btn('success', 'save')(@click="validation()" :loading="isLoaded").mt-5
</template>
<script>
import { VueEditor } from 'vue2-editor'
import { validate, validationField } from '@/mixins/validation'
import { mapState } from 'vuex'
export default {
  components: { VueEditor },
  props: {
    isLoaded: Boolean
  },
  data () { 
    return {
      version: '',
      content: '',
      validationField
    } 
  },
  validations: validate.versions,

  mounted () {
    let isEmpty = Object.keys(this.edited).length
    this.version = isEmpty ? this.edited.version : ''
    this.content = isEmpty ? this.edited.description : ''
  },
  computed: {
    ...mapState({edited: state => state.version.edited})
  },
  methods: {
    validation () {
      this.$v.$touch()
      !this.$v.$invalid &&
      this.$emit("getContent", {version: `${this.version}`, description: this.content})
    }
  }
}
</script>
