<template lang="pug">
  include ../../assets/pug/index
  .guide-versions
    .d-flex.justify-end.mb-5
      +btn('primary', 'btn.add')(v-if="!showEditor" @click="toggleEditor")
      v-icon(v-else @click="toggleEditor" x-large) mdi-close-circle-outline
    .d-flex.justify-space-between(v-if="!isReadyData && !showEditor")
      v-skeleton-loader(
        width="300"
        type="article, article, article"
      )
      v-skeleton-loader(
          width="70%"
          type="article, article, article"
      )
    .d-flex.justify-space-between(v-else-if="!showEditor")
      .mr-6
        v-card
          v-card-title(style="justify-content: start").d-flex.pb-0 
            v-icon.mr-2.ml-4 mdi-information-outline
            span {{$t('historyChanges')}}
            v-icon.ml-11(@click.stop="isRemove = !isRemove" :color="getPencilColor") mdi-pencil
          v-card-text
            v-list.guide-versions__list
              v-list-item(v-if="!versions.length")
                v-icon mdi-clipboard-list-outline
                span.ml-3 {{ $t('notify.notFound') }}
              v-list-item(
                v-for="(item, index) in versions" 
                :key="index"
                ripple 
                @click="showVersion(item.id)" 
                :class="[{'guide-versions__list-item_active' : (item.id === current.id)}, 'guide-versions__list-item']" 
              )
                span V.{{ item.version }}
                v-icon(v-show="isRemove" @click.stop="removeHandler(item.id)" color="red") mdi-delete    
      .guide-versions__content
        v-card(v-if="current")
          v-card-title.justify-space-between
            div {{$t('version')}} V.{{current.version}}
            v-icon(@click="editHandler") mdi-pencil
          v-card-subtitle {{$t('updated')}} {{getShortDateFormat(current.updated_at)}}
          v-card-text 
            .text--primary(v-html="current.description")
        v-card(v-else)
          v-card-title {{ $t('addVersionPLease') }}
    Editor(v-else @getContent="(data) => saveHandler(data)" :isLoaded="isLoaded")
</template>


<script>
import Editor from '@/components/molecules/GuideVersions/GuideVersionsEditor.vue'
import { getShortDateFormat } from '@/mixins/main'
import { mapActions, mapMutations, mapState} from 'vuex'
export default {
  components: {
    Editor
  },
  data () {
    return {
      current: {},
      versions: [],
      showEditor: false,
      isLoaded: false,
      isRemove: false,
      isReadyData: false,
      getShortDateFormat
    }
  },
  mounted () {
    this.$store.commit('setDataTitle', this.$i18n.t('guideVersion'))
    this.getVersion().then((r) => {
      this.versions = r.data
      this.current = r.data[0]
    }).finally(() => this.isReadyData = true)
  },
  computed: {
    ...mapState({edited: state => state.version.edited}),
    getPencilColor () {
      return this.isRemove ? 'primary' : ''
    },
  },
  methods: {
    ...mapActions(['addVersion', 'removeVersion', 'getVersion', 'updateVersion']),
    ...mapMutations(['SET_VERSION']),
    toggleEditor () {
      this.SET_VERSION({ prop: 'edited', value: {} })
      this.showEditor = !this.showEditor
    },
    editHandler () {
      this.showEditor = true
      this.SET_VERSION({ prop: 'edited', value: this.current })
    },
    showVersion (id) {
      this.current = this.versions.find((item) => item.id === id)
    },
    openSwal () {
      return this.$swal({
         title: this.$i18n.t('title.sureRemoveVersion'),
         icon: 'warning',
         buttons: [this.$i18n.t('btn.cancel'), this.$i18n.t('btn.yes')]
       })
     },
    saveHandler (data) {
      this.isLoaded = true
      let isEmpty = Object.keys(this.edited).length
      !isEmpty ? this.setVersion(data) : this.editVersion({...data, id: this.edited.id})
    },
    closeEditor () {
      this.isLoaded = false
      this.showEditor = false
    },
    versionExistedNotify () {
      this.isLoaded = false
      this.$notification.error(this.$i18n.t('notify.versionExisted'))
    },
    setVersion (data) {
      this.addVersion(data).then((r) => {
        this.$notification.success(this.$i18n.t('notify.versionAdded'))
        this.versions.unshift(r.data)
        this.current = r.data
        this.closeEditor()
      })
      .catch(err => err === 'exist' && this.versionExistedNotify())
    },
    editVersion (data) {
      this.updateVersion(data).then((r) => {
        this.$notification.success(this.$i18n.t('notify.versionEdited'))
        let index = this.versions.findIndex((item) => item.id === r.data.id)
        this.versions.splice(index, 1, r.data)
        this.current = r.data
      }).finally(() => this.closeEditor())
    },
    removeHandler (id) {  
      this.openSwal()
      .then(yes => yes && this.removeVersion(id).then(() => {
        this.versions = this.versions.filter(item => item.id !== id)
        this.current = this.versions[0]
        this.$notification.success(this.$i18n.t('notify.versionRemoved'))
      }))
    },
  }
}
</script>

<style scoped>
.guide-versions {
  height: calc(100vh - 93px);
  overflow-y: auto;
}
.guide-versions__content > .v-card{
  position: sticky;
  top: 0;
}
.guide-versions .guide-versions__content,
.guide-versions__list-item > span {
  flex: 1
}
.v-card__title {
  font-size: 20px;
}
.guide-versions__list {
  position: relative;
  min-width: 220px;
  margin-left: 20px;
  cursor: pointer;
  font-size: 16px;
}
.guide-versions__list-item::before{
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #3d3d3d;
  opacity: 1;
  transition: all .23s linear;

}
.guide-versions__list-item:hover::before{
  opacity: 1;
}
.guide-versions__list .guide-versions__list-item_active.guide-versions__list-item{
  color: #004AD7 !important;
}
.guide-versions__list-item_active.guide-versions__list-item::before {
  background-color: #004AD7;
  transform: translateY(-50%) scale(1.5);
}

</style>